<template>
    <div class="bg-white create-big">
        <app-modal-confirm
            ref="entry_modal_delete"
            :modal-header-title="$t('common.delete confirm title')"
            @confirmed="deleteEntry()"
        >
            <template v-slot:body>
                <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
            </template>
        </app-modal-confirm>

        <validation-observer
            ref="form_create_entry"
            v-slot="{ passes, valid, validated }"
        >
            <form class="form-create-big form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
                <div class="card card-body">
                    <div v-if="idEditing">
                        <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("common.id") }}</label>
                        <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("common.name") }} <span class="app-require">＊</span></label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-input
                            name="name"
                            type="string:40"
                            rules="required"
                            v-model="entry.name"
                        />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-xl-1 col-lg-2 col-sm-2">{{ $t("common.note") }}</label>
                        <div class="col-xl-5 col-lg-6 col-sm-6">
                        <app-text
                            name="note"
                            type="string1024"
                            v-model="entry.note"
                        />
                        </div>
                    </div>

                    <div class="card-footer row justify-content-end gr-button">
                        <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="handlerCancelButton">
                        {{ $t('common.cancel') }}
                        </button>
                        <button type="button" class="btn btn btn-danger m-r-10" :disabled="!valid && validated" @click="handleDeleteConfirm" v-if="idEditing">
                            {{ $t('common.delete') }}
                        </button>
                        <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" type="submit">
                            {{  $t('common.save') }}
                        </button>
                        <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="submit">
                            {{  $t('common.create') }}
                        </button>
                    </div>
                </div>
            </form>
        </validation-observer>
    </div>
</template>
<script>
import {createHmac} from "crypto";
import {__noticeWarning} from "../../../utils";
import {ROUTES} from "@constants";
export default {
    name: "FormPage",
    components: {

    },
    data() {
        const id = this.$route.params.id
        return {
            entry: {
                apiKeys: [],
            },
            apiKeyId: null,
            idEditing: id,
            isLineLink: '',
        }
    },

    mounted: function() {
        $(".tooltip").tooltip("hide");
        if (this.idEditing) {
            this.$request.get(this.ENDPOINT.BIG_CATEGORY_EDIT(this.idEditing)).then(res => {
                if (res.hasErrors()) {
                    this.redirectDefaultValue()
                }
                this.entry = res.data.data
            })
        }
    },

    methods: {
        handlerCancelButton() {
          if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BIG_CATEGORY_LIST) {
            this.__redirectBack()
          } else {
            this.redirectDefaultValue()
          }
        },
        async createOrUpdate() {
            let res = null;
            let msg = "";
            let params = _.cloneDeep(this.entry);
            if (this.idEditing) {
                res = await this.$request.patch(this.ENDPOINT.BIG_CATEGORY_EDIT(this.idEditing), params);
                msg = this.$t("common.msg update ok")
            } else {
                params.category_type = 0;
                res = await this.$request.post(this.ENDPOINT.BIG_CATEGORY_CREATE, params);
                msg = this.$t("common.msg create ok")
            }
            if (res.hasErrors()) {
                this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
            } else {
                this.__noticeSuccess(msg);
                await this.redirectDefaultValue()
            }
        },
        handleDeleteConfirm() {
            this.$refs.entry_modal_delete.show();
        },
        async deleteEntry() {
            const res = await this.$request.delete(this.ENDPOINT.BIG_CATEGORY_DELETE(this.idEditing));
            if (!res.hasErrors()) {
                this.__noticeSuccess(this.$t("common.msg delete ok"));
                await this.redirectDefaultValue();
            } else {
                this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
              this.$refs.entry_modal_delete.hide();
            }
        },
        async redirectDefaultValue() {
            await this.$router.push({
                name: this.ROUTES.ADMIN.BIG_CATEGORY_LIST, query: {
                'filters.id.equal': 'all',
                }
            });
        },
    },
}
</script>
